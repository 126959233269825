@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);


*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.App
{
  height: 100vh;
  width: 100%;
  background-color: #202b3e;
  display: flex;
  justify-content: center;
  color: white;
}

.todocontainer
{
  position: relative;
  top: 0px;
  transition: top 0.5s;
}

.todocontainer-active
{
  top: 40px;
}

h1{
  font-weight: 700;
  margin-bottom: 40px;
}

.appcontainer
{
  height: 500px;
  width: 400px;
  margin: 30px;
}

.checkbox
{
  height: 20px;
  width: 20px;
  background-color: #42378f;
background-image: linear-gradient(315deg, #42378f 0%, #f53844 74%); 
  border-radius: 50%;
}

.todo
{
  background-color: #131A26;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.text
{
  font-size: 19px;
  font-weight: 300;
}

h3{
  color: #61759b;
  font-weight: 500;
  margin-bottom: 30px;
}

.deletetodo
{
  background-color: rgb(139, 7, 7);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deletetodo:hover{
  background-color: green;
}

.completedtext
{
  text-decoration: line-through;
}

.dotcol
{
  background-color: #20bf55;
  background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%);

}

.addbutton
{
  position: fixed;
  bottom: 20%;
  right: 15%;
  background-color: #42378f;
  background-image: linear-gradient(315deg, #42378f 0%, #f53844 74%); 
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
  z-index: 2;

}

.addtodo
{
  background-color: #131A26;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* transition: display 10s; */
  z-index: -1;
}

.addtodo input{
  margin-bottom: 20px;
  width: 250px;
  height: 35px;
  border-radius: 20px;
  outline: none;
  font-size: 20px;
  padding: 0px 10px ;
}

.addtodo button
{
  background-color: #42378f;
  background-image: linear-gradient(315deg, #42378f 0%, #f53844 74%);
  padding: 10px;
  outline: none;
  border: none;
  color: white;
  border-radius: 30px;
}

.addtodo h3{
  color: white;
}

.addtodo-active
{
    display: flex;

    -webkit-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
}

@-webkit-keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

